:root {
  padding: 0;
  margin: 0;
  .App{
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100%;
  }
}